import Konva from "konva";
import {App} from "../app";
import {Company} from "../interfaces/company";
import {FilterTypes} from "../enums/filter";


export class Position {
    id: number = 0;
    positionID: number = 0;

    companies: Company[] = [];

    color: string = '#af2929';
    colorHighlight: string = 'lightblue';
    colorFiltered: string = '#182d8e';
    colorFilterDisabled: string = 'gray';


    box: Konva.Rect | undefined;
    text: Konva.Text | undefined;
    group: Konva.Group = new Konva.Group();

    canHovered: boolean = true;
    filter: FilterTypes = FilterTypes.NONE;

    constructor(
        public app: App,
        public x: number,
        public y: number,
        public width: number,
        public height: number,
        public label: string | undefined = undefined,
        public bgColor: string = "",
        public isInformation: boolean = false,
        public labelVertical: boolean = false,
        public textColor: string = "",
        public fontSize: number = 0,
    ) {
        if ( !this.isInformation ) {
            this.id = this.app.positions.length;
            this.positionID = this.id + 1;
        } else {
            this.canHovered = false;
        }
        this.create();
    }

    create() {
        if ( this.app.layer === undefined ) return;

        this.box = new Konva.Rect({
            id: this.id.toString(),
            x: this.x,
            y: this.y,
            width: this.width,
            height: this.height,
            fill: (this.bgColor != "" ? this.bgColor : this.color),
            stroke: '#0b0e23',
            strokeWidth: 2,
            cornerRadius: 5
        });

        const labelOrNumber = (this.label ? this.label : (this.id + 1).toString());

        let x = this.x;
        let y = this.y;

        if ( this.labelVertical ) {
            x = this.x + this.width + (this.width / 2) - 30;
            y = this.y + 30;
        }

        const fontSize = (this.fontSize != 0 ? 50 + this.fontSize : 50);

        this.text = new Konva.Text({
            x: x,
            y: y,
            align: 'center',
            width: this.width,
            height: this.height,
            verticalAlign: "middle",
            fontStyle: 'bold',
            lineHeight: (this.labelVertical ? 0.9: 1),
            text: labelOrNumber,
            fontSize: (this.labelVertical ? fontSize - 5 : fontSize),
            fill: 'white',
            listening: false,
            rotation: (this.labelVertical ? 90 : 0),
            fontFamily: 'Roboto'
        });

        this.group.add(this.box);
        this.group.add(this.text);

        this.app.layer.add(this.group);

        this.box.on('pointerenter', this.onHover.bind(this));
        this.box.on('pointerleave', this.onLeave.bind(this));

        this.text.on('pointerenter', this.onHover.bind(this));
        this.text.on('pointerleave', this.onLeave.bind(this));

        this.box.on('click', this.onClick.bind(this));
        this.text.on('click', this.onClick.bind(this));

    }

    destroy() {}

    onHover() {
        if ( this.box === undefined ) return;
        if ( this.companies.length === 0 ) return;

        console.log('filter:', this.filter);
        if ( this.filter === FilterTypes.DISABLED ) return this.box.fill(this.colorFilterDisabled);
        if ( this.filter === FilterTypes.HIGHLIGHTED ) return this.box.fill(this.colorHighlight);

        this.box.fill(this.colorHighlight);
    }

    onLeave() {
        if ( this.box === undefined ) return;
        if ( !this.canHovered ) return;


        if ( this.filter === FilterTypes.DISABLED ) return this.box.fill(this.colorFilterDisabled);
        //if ( this.filter === FilterTypes.HIGHLIGHTED ) return this.box.fill(this.colorFiltered);

        this.box.fill(this.color);
    }

    onClick() {
        this.app.emit('info', this);

        if (this.label === 'food') this.app.emit('food');
    }

    reset() {
        if ( !this.box ) return;
        if ( !this.canHovered ) return;

        if ( this.filter === FilterTypes.DISABLED ) return this.box.fill(this.colorFilterDisabled);
        //if ( this.filter === FilterTypes.HIGHLIGHTED ) return this.box.fill(this.colorFiltered);

        this.box.fill(this.color);
    }

    update() {
        if ( this.box === undefined ) return;
        if ( this.text === undefined ) return;


        this.box.x(this.x);
        this.box.y(this.y);

        this.text.x(this.x + ( this.width / 2) - 15);
        this.text.y(this.y + ( this.height / 2) - 12);
    }

    highlight(color: string = "#8e1818") {
        if ( this.box === undefined ) return;
        this.box.fill((color ? color : this.colorHighlight));
    }
}