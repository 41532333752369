import Konva from "konva";
import {Position} from "./konva/position";


export class App {
    currentScale = 1;


    stage: Konva.Stage | undefined;
    layer: Konva.Layer | undefined;
    konvaContainer: HTMLElement | null | undefined;
    positions: Position[] = [];
    stuffs: Position[] = [];
    events: any[] = [];

    dragMode: boolean = false;
    dragElement: Position | undefined = undefined;


    constructor() {
        this.start();
    }

    on(name: string, _func: (args: any) => void) {
        //console.log(name, 'created');
        this.events.push({ name: name, func: _func});
    }

    emit(name: string, data: any = undefined) {
        const event = this.events.find( ( evt ) => evt.name === name);
        if ( event === undefined ) return console.log(`event ${name} not found!`);

        return event.func(data);
    }

    start() {
        this.konvaContainer = document.getElementById('container');
        if ( this.konvaContainer == null ) return //console.log('cannot find konva container!');

        this.stage = new Konva.Stage({
            container: 'container',
            width: this.konvaContainer.offsetWidth,
            height: this.konvaContainer.offsetHeight,
            draggable: false,
        });

        this.layer = new Konva.Layer();
        this.stage.add(this.layer);

        console.log('App Started');

        Konva.Image.fromURL('/plan.png', (node: any) => {
            if ( this.layer === undefined ) return;

            console.log(node.width(), node.height());

            node.x(Number('-' + node.width() / 2));
            node.y(Number('-' + node.height() / 2));
            node.id("image");
            this.layer.add(node);

            // HALLE 1
// --------------------------------------------------------------------------------------
            //Reihe 6 Nummer 01 - 04
            this.positions.push(new Position( this, 790, -45, 80, 100));            // 01
            this.positions.push(new Position( this, 790, -240, 80, 190));           // 02
            this.positions.push(new Position( this, 790, -325, 80, 80));            // 03
            this.positions.push(new Position( this, 790, -410, 80, 80));            // 04
// --------------------------------------------------------------------------------------
            //Reihe 5 Rechts Nummer 05 - 07
            this.positions.push(new Position( this, 600, -110, 80, 170));           // 05
            this.positions.push(new Position( this, 600, -240, 80, 125));           // 06
            this.positions.push(new Position( this, 600, -415, 80, 170));           // 07
            //Reihe 5 Links Nummer 08 - 11
            this.positions.push(new Position( this, 515, -70, 80, 130));            // 08
            this.positions.push(new Position( this, 515, -155, 80, 80));            // 09
            this.positions.push(new Position( this, 515, -290, 80, 130));           // 10
            this.positions.push(new Position( this, 515, -415, 80, 120));           // 11
// --------------------------------------------------------------------------------------
            //Reihe 4 Rechts Nummer 12 - 14
            this.positions.push(new Position( this, 325, -110, 80, 170));           // 12
            this.positions.push(new Position( this, 325, -280, 80, 165));           // 13
            this.positions.push(new Position( this, 325, -420, 80, 135));           // 14
            //Reihe 4 Links Nummer 15 - 17
            this.positions.push(new Position( this, 240, -110, 80, 170));           // 15
            this.positions.push(new Position( this, 240, -280, 80, 165));           // 16
            this.positions.push(new Position( this, 240, -420, 80, 135));           // 17
// --------------------------------------------------------------------------------------
            //Reihe 3 Rechts Nummer 18 - 20
            this.positions.push(new Position( this, 50, -110, 80, 170));            // 18
            this.positions.push(new Position( this, 50, -280, 80, 165));            // 19
            this.positions.push(new Position( this, 50, -420, 80, 135));            // 20
            //Reihe 3 Links Nummer 21 - 24
            this.positions.push(new Position( this, -35, -45, 80, 105));            // 21
            this.positions.push(new Position( this, -35, -240, 80, 190));           // 22
            this.positions.push(new Position( this, -35, -325, 80, 80));            // 23
            this.positions.push(new Position( this, -35, -420, 80, 90));            // 24
// --------------------------------------------------------------------------------------
            //Reihe 2 Rechts Nummer 25 - 27
            this.positions.push(new Position( this, -225, -110, 80, 170));          // 25  
            this.positions.push(new Position( this, -225, -280, 80, 165));          // 26 
            this.positions.push(new Position( this, -225, -420, 80, 135));          // 27
            //Reihe 2 Links Nummer 28 - 31
            this.positions.push(new Position( this, -310, -45, 80, 105));           // 28
            this.positions.push(new Position( this, -310, -220, 80, 170));          // 29
            this.positions.push(new Position( this, -310, -325, 80, 100));          // 30
            this.positions.push(new Position( this, -310, -420, 80, 90));           // 31
// --------------------------------------------------------------------------------------
            //Reihe 1 Links Nummer 32 - 35
            this.positions.push(new Position( this, -440, 20, 80, 100));            // 32
            this.positions.push(new Position( this, -440, -65, 80, 80));            // 33
            this.positions.push(new Position( this, -440, -365, 80, 130));          // 34
            this.positions.push(new Position( this, -440, -500, 80, 130));          // 35
// --------------------------------------------------------------------------------------
            //Links neben der Halle 36 - 40
            this.positions.push(new Position( this, -800, 400, 500, 200));          // 36
            this.positions.push(new Position( this, -970, 130, 100, 250));          // 37
            this.positions.push(new Position( this, -970, -130, 100, 250));         // 38
            this.positions.push(new Position( this, -950, -300, 80, 100));          // 39
            this.positions.push(new Position( this, -875, -570, 200, 200));         // 40

            Konva.Image.fromURL('/halle_2.png', (pfeil: any) => {
                pfeil.x(-1280);
                pfeil.y(-570);
                pfeil.width(400);
                pfeil.height(180);
                pfeil.id("pfeil_unten");
                 this.layer!.add(pfeil);
              });

            // HALLE 2
          /*  const halleGrundriss = new Konva.Line({
                points: [
                    -1690, -1310,
                    -1690, -310,
                    -2510, -310,
                    -2510, -1310,
                ],
                fill: '#dedede',
                stroke: 'black',
                strokeWidth: 3,
                closed: true,
            }); */

            //this.layer.add(halleGrundriss);
            
// -----------------------------------------------------------------------------------------
            // Obere Reihe Nummer 41 - 46
            this.positions.push(new Position( this, -1400, -650, 100, 80));        // 41
            this.positions.push(new Position( this, -1505, -650, 100, 80));        // 42
            this.positions.push(new Position( this, -1640, -650, 130, 80));        // 43
            this.positions.push(new Position( this, -1775, -650, 130, 80));        // 44
            this.positions.push(new Position( this, -1910, -650, 130, 80));        // 45
            this.positions.push(new Position( this, -2145, -650, 230, 80));        // 46

// -----------------------------------------------------------------------------------------
            // Linke Reihe Nummer 47 - 51
            this.positions.push(new Position( this, -2195, -470, 80, 150));        // 47
            this.positions.push(new Position( this, -2195, -315, 80, 150));        // 48
            this.positions.push(new Position( this, -2195, -160, 80, 150));        // 49
            this.positions.push(new Position( this, -2195, -5, 80, 150));          // 50
            this.positions.push(new Position( this, -2195, 150, 80, 80));          // 51

// -----------------------------------------------------------------------------------------
            // Untere Reihe Nummer 52 - 55
            this.positions.push(new Position( this, -1995, 280, 130, 80));         // 52
            this.positions.push(new Position( this, -1860, 280, 130, 80));         // 53
            this.positions.push(new Position( this, -1725, 280, 130, 80));         // 54
            this.positions.push(new Position( this, -1590, 280, 130, 80));         // 55

// -----------------------------------------------------------------------------------------
            // Rechte Reihe Nummer 56 - 60
            this.positions.push(new Position( this, -1355, 90, 80, 150));          // 56
            this.positions.push(new Position( this, -1355, 5, 80, 80));            // 57
            this.positions.push(new Position( this, -1355, -140, 80, 150));        // 58
            this.positions.push(new Position( this, -1355, -225, 80, 80));         // 59
            this.positions.push(new Position( this, -1355, -380, 80, 150));        // 60

// -----------------------------------------------------------------------------------------
            // Mittlere Reihe Oben Nummer 61 - 71
            this.positions.push(new Position( this, -1555, -420, 100, 100));       // 61
            this.positions.push(new Position( this, -1660, -420, 100, 100));       // 62
            this.positions.push(new Position( this, -1795, -420, 130, 100));       // 63
            this.positions.push(new Position( this, -1900, -400, 100, 80));        // 64
            this.positions.push(new Position( this, -2005, -400, 100, 80));        // 65
            this.positions.push(new Position( this, -2005, -315, 90, 80));         // 66
            this.positions.push(new Position( this, -1910, -315, 90, 80));         // 67
            this.positions.push(new Position( this, -1815, -315, 90, 80));         // 68
            this.positions.push(new Position( this, -1720, -315, 90, 80));         // 69
            this.positions.push(new Position( this, -1625, -315, 90, 80));         // 70
            this.positions.push(new Position( this, -1530, -315, 75, 80));         // 71

// -----------------------------------------------------------------------------------------
            // Mittlere Reihe Mitte Nummer 72 - 80
            this.positions.push(new Position( this, -1530, -165, 75, 80));         // 72
            this.positions.push(new Position( this, -1835, -165, 300, 80));        // 73
            this.positions.push(new Position( this, -1920, -165, 80, 80));         // 74
            this.positions.push(new Position( this, -2005, -165, 80, 80));         // 75
            this.positions.push(new Position( this, -2005, -80, 100, 80));         // 76
            this.positions.push(new Position( this, -1900, -80, 100, 80));         // 77
            this.positions.push(new Position( this, -1795, -80, 100, 80));         // 78
            this.positions.push(new Position( this, -1690, -80, 120, 80));         // 79
            this.positions.push(new Position( this, -1565, -80, 110, 80));         // 80

// -----------------------------------------------------------------------------------------
            // Mittlere Reihe Unten Nummer 81 - 89
            this.positions.push(new Position( this, -1620, 70, 165, 165));         // 81
            this.positions.push(new Position( this, -1725, 70, 100, 80));          // 82
            this.positions.push(new Position( this, -1830, 70, 100, 80));          // 83
            this.positions.push(new Position( this, -1930, 70, 95, 80));           // 84
            this.positions.push(new Position( this, -2005, 70, 70, 80));           // 85
            this.positions.push(new Position( this, -2005, 155, 95, 80));          // 86
            this.positions.push(new Position( this, -1905, 155, 70, 80));          // 87
            this.positions.push(new Position( this, -1830, 155, 100, 80));         // 88
            this.positions.push(new Position( this, -1725, 155, 100, 80));         // 89



            // Sonstiges / Aussen
            
  // ---------------------------------------------------------------------------------------------------  
         // Halle 1       
            this.stuffs.push(new Position(this, 166, -550, 240, 70, "IMBISS", "#BBAF4C", true));       
            this.positions.push(new Position( this, -800, -110, 300, 490, "65a"));   // 65a

            Konva.Image.fromURL('/pfeil_unten.png', (pfeil: any) => {
                pfeil.x(-90);
                pfeil.y(100);
                pfeil.width(120);
                pfeil.height(120);
                pfeil.id("pfeil_unten");
                 this.layer!.add(pfeil);
              });

              this.stuffs.push(new Position(this, 130, 165, 140, 70, "INFO", "#BBAF4C", true));

              Konva.Image.fromURL('/wc.png', (wc: any) => {
                wc.x(-320);
                wc.y(250);
                wc.width(120);
                wc.height(60);
                wc.id("wc");
                 this.layer!.add(wc);
              });

            this.stuffs.push(new Position(this, -585, -420, 100, 150, "INFO", "#BBAF4C", true, true, "", -5));

            this.stuffs.push(new Position(this, -85, 500, 370, 100, "EINGANG", "#BBAF4C", true));

            Konva.Image.fromURL('/pfeil_links.png', (pfeil: any) => {
                pfeil.x(-570);
                pfeil.y(-245);
                pfeil.width(120);
                pfeil.height(120);
                pfeil.id("pfeil_links");
                 this.layer!.add(pfeil);
              });

// ---------------------------------------------------------------------------------------------------
              //Halle 2 pfeile
              Konva.Image.fromURL('/pfeil_unten.png', (pfeil: any) => {
                pfeil.x(-2120);
                pfeil.y(330);
                pfeil.width(120);
                pfeil.height(120);
                pfeil.id("pfeil_unten");
                this.layer!.add(pfeil);
            });
            
            Konva.Image.fromURL('/pfeil_unten.png', (pfeil: any) => {
                pfeil.x(-1455);
                pfeil.y(330);
                pfeil.width(120);
                pfeil.height(120);
                pfeil.id("pfeil_unten");
                this.layer!.add(pfeil);
            });            

              Konva.Image.fromURL('/pfeil_rechts.png', (pfeil: any) => {
                pfeil.x(-1405);
                pfeil.y(-535);
                pfeil.width(120);
                pfeil.height(120);
                pfeil.id("pfeil_rechts");
                 this.layer!.add(pfeil);
              });


            Konva.Image.fromURL('/logo_new.png', (node: any) => {
                if ( !this.layer ) return;

                node.x(-1440);
                node.y(-1450);
                node.width(1500);
                node.height(600);
                node.id("image-logo-white");
                this.layer.add(node);
            });

            this.layer.add(new Konva.Text({
                x: -1365,
                y: -1030,
                text: 'www.oberkircher-berufsinfomesse.de',
                fontSize: 50,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));

            this.layer.add(new Konva.Text({
                x: -80,
                y: -870,
                text: 'HALLE 1',
                fontSize: 150,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));
            this.layer.add(new Konva.Text({
                x: -2000,
                y: -870,
                text: 'HALLE 2',
                fontSize: 150,
                fill: '#9f9f9f',
                fontFamily: 'Roboto'
            }));

            if ( !this.stage ) return;

            const offset = 100;
            this.stage.absolutePosition({x: (this.stage.width() / 2) + offset, y: (this.stage.height() / 2) + offset});
            //this.stage.absolutePosition({x: -200, y: 200});
            this.zoom(1);
            this.onMouseWheel(undefined);
        });

        //this.stage.on('wheel', this.onMouseWheel.bind(this));
    }

    onMouseWheel( event: any ) {
        if ( !event ) return this.emit('zoom', (this.currentScale * 100));

        if ( event.evt.deltaY < 0 ) {
            this.currentScale += .1;
            //console.log('up');
        } else {
            //console.log('down');
            this.currentScale -= .1;
        }

        this.emit('zoom', (this.currentScale * 100));
    }

    zoom( scale: number = -1 ) {
        if ( this.stage === undefined ) return;


        if ( scale === -1 )
            return this.stage.scale({ x: 1, y: 1 });

        //if ( scale <= 0.4 ) scale = 0.4;
        //if ( scale >= 2 ) scale = 2;

        this.currentScale = scale;

        return this.stage.scale({ x: scale, y: scale });
    }
}